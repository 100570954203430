<template>
  <B2b3_4Layout>
    <Register />
  </B2b3_4Layout>
</template>

<script>
import { defineComponent } from "vue";
import Register from "../components/b2b3/Register.vue";
import B2b3_4Layout from "../layout/B2b3_4Layout.vue";

export default defineComponent({
  name: "B2b3",
  components: {
    B2b3_4Layout,
    Register,
  },
});
</script>