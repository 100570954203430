<template>
  <label class="mr-4">
    <input type="checkbox" :id="label" :value="val" @input="click" :checked="checked"/>
    <div class="square-checkbox"></div>
    <label :for="label"
      >{{ label }}
      <span
        :class="[
          flag === 'Offres' ? 'offres' : '',
          flag === 'Transactions' ? 'transactions' : '',
        ]"
        >{{ flag }}</span
      ></label
    >
  </label>
</template>

<script>
export default {
  name: "checkbox",
  props: ["value", "val", "label", "flag", "checked","list"],
  data() {
    return {
      model: [],
    };
  },
  methods: {
    click(e) {
      //if (this.value) {
       // this.model = [...this.value];
     // }
     // if (e.target.checked) {
        this.model.push(e.target.value);
     // } 
      //else {
      //  this.model = this.model.filter((i) => i !== e.target.value);
      //}
      this.$emit("input", this.model);
    },
  },
};
</script>

<style scoped>
/*form*/
.square .square-checkbox::before,
.square-checkbox {
  border-radius: 25%;
}
.rounded .square-checkbox,
.square-checkbox::before {
  border-radius: 25%;
}
/*end form*/
label input {
  display: none;
}
label {
    display: block;
    margin-top: 4px;
    cursor: pointer;
    padding-left: 15px;
    position: relative;
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #363660;
    margin-bottom: 0!important;
}
label .square-checkbox {
  top: 2px;
  height: 18px;
  width: 18px;
  border: 1px solid #00c1e2;
  /* border-radius: 25%;*/
  display: inline-block;
  position: absolute;
  left: 0;
  transition: border-color 0.5s ease;
}
label .square-checkbox::before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: #00c1e2;
  position: absolute;
  left: 0;
  /*border-radius: 25%;*/
  box-sizing: border-box;
  margin-left: 4px;
  margin-top: 4px;
  transition: transform 0.5s ease;
  transform: scale(0);
}
label span {
  display: inline-block;
  transition: color 0.5s ease;
}
label input:checked + .square-checkbox {
  border-color: #00c1e2;
}
label input:checked + .square-checkbox::before {
  transform: scale(1);
}
.transactions {
  margin: 1px 0 0;
  padding: 1px 6px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16);
  background-color: #3d42df;
  color: white;
}
</style>