<template>
  <div>
    <h1 class="text1">Bienvenue chez ExVal</h1>
    <p class="text2">Demarrez votre essaie gratuit de 14 jours</p>
    <form class="grid grid-cols-4 gap-3">
      <input placeholder="Nom" v-model="firstname" type="text" class="col-span-2" v-bind:class="firstError == true ? 'hasError' : ''" />
      <input placeholder="Prénom" v-model="lastname" type="text" class="col-span-2" v-bind:class="lastError == true ? 'hasError' : ''"/>
      <input placeholder="Adresse email" v-model="email" type="email" class="col-span-4" @change="validateEmail()" v-bind:class="emailError == true ? 'hasError' : ''"/>
      <div class="col-span-4" style="margin-bottom: 24px;">
        <!--input placeholder="France" class="col-span-2" />
        <input placeholder="+ 33" class="col-span-4" /-->
        <vue-tel-input v-model="phone" class="col-span-4 lg:col-span-3" id="phone"></vue-tel-input>

      </div>
      <input placeholder="Entreprise" class="col-span-4" type="text" v-model="society_name" v-bind:class="society_nameErr == true ? 'hasError' : ''"/>
      <div id="modal-container" class="col-span-2 pe-0 pl-0">
        <input placeholder="Secteur d'activite" class="col-span-2" type="text" style="width: 100%;" v-model="activity" @focus="toggleModalType" v-bind:class="activityError == true ? 'hasError' : ''"/>
          
        <Selecter 
          v-if="modalType == true"
          :options="options"
          :v-model="modalType"
          @onChange="onChange"
          @toggleModalType="toggleModalType"
          @hideModal="hideModal"
        />
      </div> 
      <input placeholder="Taille de l'entreprise" class="col-span-2" type="number" v-model="society_number"/>
      <input placeholder="Mot de passe" v-model="password" type="password" class="col-span-2" />
      <input placeholder="Confimation mot de passe" v-model="password_conf" type="password" class="col-span-2" />
      <input placeholder="Pays" class="col-span-2" v-model="contry"/>
      <input placeholder="Nombre d'utilisateurs" class="col-span-2" v-model="quantity_user"/>

      
      <div class="row">
          <div id="card-element">
          </div>
          <div id="card-errors" role="alert"></div>
      </div>
      <div class="col-span-4 mt-2 flex">
        <Checkbox />
        <span class="text3">
          J'accepte les
          <a href="#" class="underline">Conditions Générales</a> et de
          <a href="#" class="underline">Confidentialité</a> de ExVal, et je
          consens à la collecte et à l'utilisation de mes données à des fins
          commerciales.
        </span>
      </div>

      <button class="col-span-4 submit-btn">
        Commencer votre version essai
      </button>
      <router-link
        :to="{ name: 'connexion' }"
        class="col-span-4 ou-connectez-vous"
        ><span class="text-style-1">ou</span> connectez-vous
      </router-link>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Checkbox from "../public/Checkbox.vue";
import Selecter from './select'
const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )

export default defineComponent({
  name: "Register",
  components: {
    Checkbox,
    Selecter
  },
  data() {
    return {
      phone: '',
      firstname: '',
      firstError: false,
      lastname: '',
      lastError: false,
      email: '',
      emailError: false,
      society_name: '',
      society_nameErr: false,
      activity: "",
      activityError: false,
      password: "",
      passwordError: false,
      password_conf: "",
      passwordConfError: false,
      quantity_user: '',
      society_numbeer: '',
      contry:'',

      modalType: false,
      options: [
        {
            id: 1,
            label: "Notaire",
            value: "Notaire",
            flag: null,
            checked: false
        },
        {
            id: 2,
            label: "Conseiller Financier",
            value: "Conseiller Financier",
            flag: null,
            checked: false

        },
        {
            id: 3,
            label: "Expert Immobilier",
            value: "Expert Immobilier",
            flag: null,
            checked: false

        },
        {
            id: 4,
            label: "Banque",
            value: "Banque",
            flag: null,
            checked: false

        },
        {
            id: 5,
            label: "Conseiller en Gestion de Patrimoine",
            value: "Conseiller en Gestion de Patrimoine",
            flag: null,
            checked: false

        },
        {
            id: 6,
            label: "Autres",
            value: "Autres",
            flag: null,
            checked: false

        },
      ],
    }
  }, 
  methods:{
    toggleModalType() {
        this.modalType = !this.modalType;
        if(this.modalType == true) {
            const element = document.getElementById('modal-container');
            this.hideOnClickOutside(element);
        }
    },
    
    hideOnClickOutside(element) {
        const outsideClickListener = event => {
        //const bien = document.getElementById('modal-bien')
        if(element.contains(event.target) == false && isVisible(element)) { 
            this.hideModal()
            removeClickListener()
        }
        }
        const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener)
        }
        document.addEventListener('click', outsideClickListener)
    },
    
    hideModal() {
        this.modalType = false
    }, 
    onChange(value) {
        const options = this.options.map(function(item) {  

          if(value.includes(item.value)) {
            if(item.checked == false)
                item.checked = true
            else
                item.checked = false            
          } else {
                item.checked = false
          }
          return item
        })
        this.options = [...options]
    
        this.activity = value[0];

    },
    
    validateEmail() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const v = re.test(String(this.email).toLowerCase());
        this.emailError = v == false ? true : false
    },
  }
});
</script>

<style scoped>
input {
  height: 50px;
  border-radius: 4px;
  border: solid 1px #c4c4c4;
  background-color: #ffffff;
  padding-left: 17px;
}
input::placeholder {
  height: 16px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
}
div:nth-child(4) input:nth-child(2)::placeholder {
  color: #363660;
}

.text1 {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: left;
  color: #363660;
}

.text2 {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  margin-bottom: 24px;
}

.text3 {
  width: 348px;
  height: 60px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}

.submit-btn {
  padding: 16px 94px 16px 93px;
  border-radius: 4px;
  background-color: #e94a73;

  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.ou-connectez-vous {
  margin: 16px 113px 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #4cbfde;
}
.ou-connectez-vous .text-style-1 {
  color: #363660;
}
.vti__input {

  
}
#phone input {
    height: 50px;
    border-radius: 4px;
    border: solid 1px #c4c4c4;
    background-color: #ffffff;
    padding-left: 17px;
}

input {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  margin-bottom: 24px
}
.hasError {
  border: 1px solid red;
}
</style>